import { Col, Typography, Row, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RealmContext } from "../context/realmProvider";
const { Title } = Typography;
function Short(props) {
    const { short } = useParams();
    const { user } = useContext(RealmContext);
    const [assessment, setAssessment] = useState();
    const [loading, setLoading] = useState(true);
    const handleLoad = async () => {
        await fetch(
            `https://ap-southeast-1.aws.data.mongodb-api.com/app/wowed-ijfty/endpoint/updatestatus?short=${short}`
        );
    };

    useEffect(() => {

        const res = fetch(
            `https://apis.a247.co/api/handle/getlink?short=${short}`
            //`https://ap-southeast-1.aws.data.mongodb-api.com/app/wowed-ijfty/endpoint/getAssessmentLinkByShort?short=${short}`
        ).then(async (result) => {
            const _assessment = await result.json();
            //console.log(_assessment);
            if(_assessment.error){                
                fetch(
                    "https://ap-southeast-1.aws.data.mongodb-api.com/app/wowed-ijfty/endpoint/mailgun",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            from: `[Lỗi hệ thống] <info@a247.co>`,
                            to: "vunh2301@gmail.com",
                            cc: "vuhoan.ng92@gmail.com",
                            subject: `${_assessment.undefined ? "Nghiêm trọng" : "Cảnh báo"} - [${_assessment.firstname} ${_assessment.lastname}] - https://a247.co/${short}`,
                            "o:tag": "a247.co",
                            html:
                                "<p>Authentication: " + user?.profile?.email + "</p><br/><pre>" +
                                JSON.stringify(_assessment, null, 2) +
                                `</pre><br/>Error Open link: <a href="https://a247.co/${short}">https://a247.co/${short}</a>`,
                        }),
                    }
                );
            }else{
                // fetch(
                //     "https://ap-southeast-1.aws.data.mongodb-api.com/app/wowed-ijfty/endpoint/mailgun",
                //     {
                //         method: "POST",
                //         body: JSON.stringify({
                //             from: `[${_assessment.firstname} ${_assessment.lastname}] <info@a247.io>`,
                //             to: "vunh2301@gmail.com",
                //             subject: "Logs a247.io",
                //             "o:tag": "a247.io",
                //             html:
                //             "<p>Authentication: " + user?.profile?.email + "</p><br/><pre>" +
                //                 JSON.stringify(_assessment, null, 2) +
                //                 "</pre>",
                //         }),
                //     }
                // );
            }

            if (
                _assessment.link &&
                (_assessment.type == "Motivators" ||
                    _assessment.type == "DISC") &&
                _assessment.language == "Vietnamese"
            ) {
                setAssessment({
                    ..._assessment,
                    link:
                        _assessment.link.replace(
                            "https://actioncoachassessments.com/",
                            "https://proxy.a247.co/"
                        ) +
                        "&short=" +
                        short,
                });
                // setAssessment({
                //     ..._assessment,
                //     link: _assessment.link,
                // });
                setLoading(false);
            } else {
                setAssessment(_assessment);
                setLoading(false);
            }
            if (_assessment.download)
                window.location.replace(_assessment.download);
            if (_assessment.error) setLoading(false);
        });
    }, []);
    if (short && short.length === 5) {
        return (
            <>
                {loading && (
                    <Spin tip="Loading" size="large">
                        <div
                            style={{
                                height: "100vh",
                                width: "100vw",
                            }}
                        ></div>
                    </Spin>
                )}
                <div
                    style={{
                        position: "absolute",
                        visibility: loading ? "hidden" : "visible",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        overflow: "hidden",
                    }}
                >
                    {assessment && assessment.link && !assessment.error && (
                        <iframe
                            width="100%"
                            onLoad={handleLoad}
                            height="100%"
                            border="0"
                            style={{
                                height: "100vh",
                                width: "100vw",
                                border: "none",
                            }}
                            src={assessment.link}
                        />
                    )}

                    {assessment && assessment.error && (
                        <Row
                            style={{
                                width: "100%",
                                padding: "40px",
                                height: "100vh",
                            }}
                            justify="center"
                            align="middle"
                        >
                            <Col>
                                <Title
                                    style={{
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "20px",
                                    }}
                                    type="danger"
                                >
                                    {assessment.message?.message?.includes(
                                        "ASP.NET_SessionId"
                                    ) || assessment.undefined
                                        ? "Máy chủ Assessments 24x7 Global đang gặp sự cố bất thường, vấn đề đang được xử lý, vui lòng thử tải lại trang hoặc quay lại sau, thành thật xin lỗi về bất tiện này."
                                        : "Liên kết không tồn tại hoặc đã Hết hạn!"}
                                </Title>
                                <span>
                                    <strong>Hỗ trợ khách hàng: </strong>
                                </span>
                                <span>
                                    <a href="mailto:support@a247.io">
                                        support@a247.io
                                    </a>
                                </span>
                            </Col>
                        </Row>
                    )}
                </div>
            </>
        );
    }

    return <div></div>;
}

export default Short;
